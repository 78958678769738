.modal {
  display: none; /* ocultar el modal por defecto */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* agregar un fondo oscuro para resaltar el modal */
  z-index: 9999; /* asegurarse de que el modal aparezca por encima de otros elementos en la página */
}

.active {
  display: flex; /* mostrar el modal cuando se agrega la clase "active" */
  justify-content: center; /* alinear el contenido verticalmente */
  align-items: center; /* alinear el contenido horizontalmente */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* agregar un fondo oscuro para resaltar el modal */
  z-index: 9999; /* asegurarse de que el modal aparezca por encima de otros elementos en la página */
}

.modalContent {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto; /* permitir que el contenido se desplace dentro del modal */
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
}
